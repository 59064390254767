import $ from 'jquery';

// load CSRF cookie
$(document).ready(function() {
    // get CSRF cookie as JSON and save it as global variable

    $.get("/api/getcsrf", function(data) {
        window.csrfTokenName = data.csrfTokenName;
        window.csrfTokenValue = data.csrfTokenValue;
    }, "json");
});


// Slick slider carousel related
const slickSlider = document.querySelector('.slider.slider-home');

function setSliderTheme() {
    const activeSlide = document.querySelector('.slick-slide.slick-active .slide');

    console.log(activeSlide)

    if(activeSlide && activeSlide.classList.contains('theme--yellow')) {
        console.log('add class')
        slickSlider.classList.add('theme--yellow__slider');
    } else {
        console.log('remove class')
        slickSlider.classList.remove('theme--yellow__slider');
    }
}

document.addEventListener('DOMContentLoaded', async () => {

    $(slickSlider).slick({
        accessibility: true,
        dots: true,
        arrows: false,
        infinite: true,
        threshold: 20,
    });


    $(slickSlider).on('swipe', function(event, slick){
        setSliderTheme();
    });

    $(slickSlider).on('click', function(event, slick){
        setSliderTheme();
    });
});

document.addEventListener('readystatechange', () => {
    $(slickSlider).on('init', function(slick){
        setSliderTheme();
    });
});
