// Importing scripts
import * as bootstrap from 'bootstrap';
import $ from 'jquery';

// slick slider / carousel
import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';

// fancybox
import 'fancybox';

// Importing custom scripts + styles
import './scss/app.scss';
import './js/scripts';



// Accept HMR
if (module && module.hot) {
    module.hot.accept();
}

window.bootstrap = bootstrap;
window.$ = $;